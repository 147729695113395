import {
  presentBoolean,
  presentDate,
  presentDateOnlyMonth,
  presentCurrency,
  presentVehicleType,
  presentOwner,
} from '@/core/helpers';

import {InsuranceTypes, VehicleTypes,} from '../enums/filters';
import {eiqMappingTranslations} from "@/core/textConstants";

const mapDataForVehicle = (fidData, vehicleType) => {
    const vehicleDetails = getVehicleDetailsByVehicleType(fidData, vehicleType)

  const ownerDetails = getOwnerDetailsByVehicleType(fidData, vehicleType)

  // Prepare the secondDriverDetails if exists
  let secondDriverDetails = {};
  if (fidData.formData.customer.driver_data.length > 1) {
    secondDriverDetails = {
      'date_of_birth': presentDate(fidData.formData.customer.driver_data[1].date_of_birth),
      'licenseyears_text': fidData.viewData.licenseYears2
    };
  }

  return {
    vehicleDetails,
    ownerDetails,
    secondDriverDetails,
  };
}

const getVehicleDetailsByVehicleType = (fidData, vehicleType) => {
    const electric = 3;

    const truckDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': presentVehicleType(fidData.formData.vehicle.vehicle_type),
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'vehicle_model_text': fidData.viewData.vehicleEdition,
        'hp_text': fidData.viewData.taxHp,
        'hasAlarm': presentBoolean(fidData.formData.vehicle.hasAlarm),
        'value': presentCurrency(fidData.formData.vehicle.value),
        'isHeavy': presentBoolean(fidData.formData.vehicle.isHeavy),
    }

    const taxiDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': eiqMappingTranslations[4],
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'vehicle_model_text': fidData.viewData.vehicleEdition,
        'hp_text': fidData.viewData.taxHp,
        'hasAlarm': presentBoolean(fidData.formData.vehicle.hasAlarm),
        'value': presentCurrency(fidData.formData.vehicle.value),
        'isHeavy': presentBoolean(fidData.formData.vehicle.isHeavy),
    }

    const rentalCarDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': eiqMappingTranslations[16],
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'vehicle_model_text': fidData.viewData.vehicleEdition,
        'hp_text': fidData.viewData.taxHp,
        'is_vehicle_used': presentBoolean(!fidData.formData.vehicle.is_vehicle_used),
        'vehicle_transfer_date': fidData.formData.vehicle.is_vehicle_used ? presentDateOnlyMonth(fidData.formData.vehicle.vehicle_transfer_date) : null,
        'value': presentCurrency(fidData.formData.vehicle.value),
        'trailer': fidData.viewData.vehicleCategory === eiqMappingTranslations[0] ? 'Χωρίς' : fidData.viewData.trailer_text
    }

    const bikeDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': presentVehicleType(fidData.formData.vehicle.vehicle_type),
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'is_vehicle_used': presentBoolean(!fidData.formData.vehicle.is_vehicle_used),
        'vehicle_transfer_date': fidData.formData.vehicle.is_vehicle_used ? presentDateOnlyMonth(fidData.formData.vehicle.vehicle_transfer_date) : null,
        'cc_range': fidData.formData.vehicle.fuel === electric ? null : fidData.viewData.taxHp,
        'is_electric': fidData.formData.vehicle.fuel === electric ? presentBoolean(true) : presentBoolean(false),
        'cc': fidData.formData.vehicle.fuel === electric ? null : fidData.formData.vehicle.cc,
        'kw': fidData.formData.vehicle.fuel === electric ? fidData.formData.vehicle.kw : null,
    }

    const bikeExtraDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': presentVehicleType(fidData.formData.vehicle.vehicle_type),
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'is_vehicle_used': presentBoolean(fidData.formData.vehicle.is_vehicle_used),
        'vehicle_transfer_date': fidData.formData.vehicle.is_vehicle_used ? presentDateOnlyMonth(fidData.formData.vehicle.vehicle_transfer_date) : null,
        'cc_range': fidData.formData.vehicle.fuel === electric ? null : fidData.viewData.taxHp,
        'is_electric': fidData.formData.vehicle.fuel === electric ? presentBoolean(true) : presentBoolean(false),
        'cc': fidData.formData.vehicle.fuel === electric ? null : fidData.formData.vehicle.cc,
        'kw': fidData.formData.vehicle.fuel === electric ? fidData.formData.vehicle.kw : null,
    }

    const carDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': eiqMappingTranslations[0],
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'vehicle_model_text': fidData.viewData.vehicleEdition,
        'hp_text': fidData.viewData.taxHp,
        'is_vehicle_used': presentBoolean(!fidData.formData.vehicle.is_vehicle_used),
        'vehicle_transfer_date': fidData.formData.vehicle.is_vehicle_used ? presentDateOnlyMonth(fidData.formData.vehicle.vehicle_transfer_date) : null,
        'value': presentCurrency(fidData.formData.vehicle.value),
        'trailer': fidData.viewData.vehicleCategory === eiqMappingTranslations[0] ? 'Χωρίς' : fidData.viewData.trailer_text
    }

    const defaultDetailFields = {
        'license_plate': fidData.formData.vehicle.license_plate,
        'vehicle_type_text': presentVehicleType(fidData.formData.vehicle.vehicle_type),
        'manufacturer_text': fidData.formData.vehicle.manufacturer_text,
        'vehicle_model_edition': getModelWithOtherModelCheck(fidData),
        'manufacturing_date': presentDateOnlyMonth(fidData.formData.vehicle.manufacturing_date),
        'vehicle_model_text': fidData.viewData.vehicleEdition,
        'hp_text': fidData.viewData.taxHp,
        'is_vehicle_used': presentBoolean(fidData.formData.vehicle.is_vehicle_used),
        'hasAlarm': presentBoolean(fidData.formData.vehicle.hasAlarm),
        'value': presentCurrency(fidData.formData.vehicle.value),
        'isHeavy': presentBoolean(fidData.formData.vehicle.isHeavy),
    }
    
    let selectedConfig = defaultDetailFields;
    switch (vehicleType) {
        case VehicleTypes.Truck:
        case VehicleTypes.CrmTruck:
        case VehicleTypes.Rural:
        case VehicleTypes.CrmRural:
            selectedConfig = truckDetailFields;
            break;
        case VehicleTypes.Taxi:
            selectedConfig = taxiDetailFields
            break;
        case VehicleTypes.RentalCar:
            selectedConfig = rentalCarDetailFields
            break;
        case VehicleTypes.Bike:
            if (fidData.context.classification.filters.packetType === InsuranceTypes.Basic)
                selectedConfig = bikeDetailFields
            else if (fidData.context.classification.filters.packetType === InsuranceTypes.Extra)
                selectedConfig = bikeExtraDetailFields
            break
        case VehicleTypes.Car:
            selectedConfig = carDetailFields
    }

    return selectedConfig;
}

const getOwnerDetailsByVehicleType = (fidData, vehicleType) => {
    const defaultOwnerFields = {
        'customerType': presentOwner(fidData.formData.customer.customer_type),
        'date_of_birth': presentDate(fidData.formData.customer.driver_data[0].date_of_birth),
        'licenseyears_text': fidData.viewData.licenseYears,
        'postcode_text': fidData.formData.customer.postal_code,
    };

    const bikeOwnerFields = {
        'customerType': presentOwner(fidData.formData.customer.customer_type),
        'date_of_birth': presentDate(fidData.formData.customer.driver_data[0].date_of_birth),
        'licenseyears_text': fidData.viewData.licenseYears,
        'postcode_text': fidData.formData.customer.postal_code,
        'job': fidData.viewData.job
    };

    let selectedConfig = defaultOwnerFields;
    switch (vehicleType) {
        case VehicleTypes.Truck:
        case VehicleTypes.CrmTruck:
        case VehicleTypes.Rural:
        case VehicleTypes.CrmRural:
        case VehicleTypes.Taxi:
        case VehicleTypes.Bike:
            selectedConfig = bikeOwnerFields
            break
    }

    return selectedConfig
}


// Legacy sends legacy manufacturer id when model is other model so we have to do this check
const getModelWithOtherModelCheck = (fidData) => {
    const truckCategoryType = 1
    const ruralCategoryType = 2
    const bikeCategoryType = 3

    const OTHER_MODEL = '99999999999'

    const numbers = [truckCategoryType, ruralCategoryType];

    if (numbers.includes(fidData.formData.vehicle.vehicle_type)) {
        return fidData.formData.vehicle.vehicle_model_edition
    }

    if ( fidData.formData.vehicle.vehicle_type === bikeCategoryType ) {
        return fidData.formData.vehicle.vehicle_model_edition_id
    }

    if ((fidData.formData.vehicle.manufacturer_id ===  fidData.formData.vehicle.vehicle_model_edition_id) || fidData.formData.vehicle.vehicle_model_edition_id === OTHER_MODEL) {
        return "Άλλο Μοντέλο"
    }

    return fidData.formData.vehicle.vehicle_model_edition_id
}

export {mapDataForVehicle, getModelWithOtherModelCheck};
